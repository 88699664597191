@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { font-family: 'December Show'; src: url('December Show.otf'); } 

body {
    @apply bg-blue-100;
}

.december-show {
    font-family: "December Show";
}